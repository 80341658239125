var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-container", [
    _c(
      "div",
      { staticClass: "app-container" },
      [
        _c(
          "v-row",
          { staticClass: "text-center" },
          [
            _c(
              "v-col",
              { attrs: { cols: "12" } },
              [
                _vm._v(" Klicken Sie hier um loszulegen!"),
                _c("br"),
                _c(
                  "v-btn",
                  { attrs: { color: "primary" }, on: { click: _vm.signIn } },
                  [_vm._v("Jetzt Anmelden")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }