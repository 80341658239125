<template>
  <v-container>
    <div class="app-container">
      <v-row class="text-center">
        <v-col cols="12">
          Klicken Sie hier um loszulegen!<br>
          <v-btn @click="signIn" color="primary">Jetzt Anmelden</v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
import router from "../router"
import store from "../store"
import { defineComponent, onMounted } from "vue"

export default defineComponent({
    setup() {
        onMounted(() => {
            if (store.state.auth.isSignedIn) {
                router.push("/")
            }
        })

        // Nicht notwendig, wenn die Anmeldung erzwungen wird
        const signIn = () => {
            loginNew.auth()
            console.log("auth")
        }

        return {
            signIn
        }
    }
})
</script>